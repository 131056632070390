<template>
  <div class="positionDetails">
    <Header/>
    <div class="positionDetailsHeader" style="margin-top: 48px">
      <div class="wrap">
        <div class="left">
          <div class="nameOrPrice">
            <div class="name shengluehao3">{{ infoDetail?.job }}</div>
            <div class="price">{{infoDetail?.salaryNote}}</div>
          </div>
          <div class="middleWrap" @mouseleave="handleMouseLeave" >
            <div class="middle">
              <div class="more" v-if="moreShow">
                <div class="welfares">
                  <div class="item" v-for="(item, index) in infoDetail?.bonusList" :key="index">{{ item }}</div>
                </div>
              </div>
              <div class="welfare">
                <div class="item" v-for="(item, index) in infoDetail?.bonusList.slice(0, 3)" :key="index">{{ item }}</div>
              </div>
              <img v-if="infoDetail?.bonusList?.length>3" src="https://static.dasdt.cn/images/more.png" @mouseenter="handleMouseEnter">
            </div>
          </div>
        </div>


        <div class="right">
          <div style="display: flex;align-items: center;gap: 24px">
            <div class="infoList">
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/adress4.png">
                <span>{{ infoDetail?.cityName }} {{ infoDetail?.areaName }}</span>
              </div>
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/education2.png">
                <span>{{ infoDetail?.education }}</span>
              </div>
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/experience2.png">
                <span>{{ infoDetail?.jobExp }}</span>
              </div>
            </div>
            <div class="timer">发布：{{ infoDetail?.createTimeFormat.slice(0,10) }}</div>

          </div>
          <div class="btns">

            <el-popover
                placement="bottom"
                width="200"
                trigger="hover">
              <div class="shareList">
                <div class="item" style="border-bottom: 1px solid #E6E6E6" @click="copyCurrentPageLink">
                  <img src="https://static.dasdt.cn/images/link3.png">
                  <span>复制分享链接</span>
                </div>
<!--                <div class="item">-->
<!--                  <img src="https://static.dasdt.cn/images/weibo.png">-->
<!--                  <span>分享到微博</span>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <img src="https://static.dasdt.cn/images/qq.png">-->
<!--                  <span>分享到QQ</span>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <img src="https://static.dasdt.cn/images/douban.png">-->
<!--                  <span>分享到豆瓣</span>-->
<!--                </div>-->
              </div>
              <div class="share" slot="reference">
                <img src="https://static.dasdt.cn/images/export.png">
                <span>分享</span>
              </div>
            </el-popover>


            <div class="share" @click="eventCollection" v-show="isShow">
              <img src="https://static.dasdt.cn/images/star3.png">
              <span>收藏</span>
            </div>
            <div class="share2" @click="eventCollection" v-show="!isShow">
              <img src="https://static.dasdt.cn/images/star5.png">
              <span>已收藏</span>
            </div>
            <div class="deliver" @click="deliver" v-if="!infoDetail?.isDeliver">投递简历</div>
            <div class="deliver2" v-else>已投递</div>
          </div>

        </div>
      </div>
    </div>
    <div class="detailsWrap">
      <div class="lefts">
        <div class="details">
          <div>
            <div class="titles" style="margin-top: 0px;font-weight: 600">职位描述</div>
            <div class="tagList">
              <div class="item" v-for="item in infoDetail?.tagList" :key="item">{{ item }}</div>
            </div>
            <div class="responsibilityWrap">
              <div class="responsibility">岗位职责：</div>
              <div class="responsibility" style="white-space: pre-wrap;">{{ infoDetail?.duty }}</div>
            </div>
            <div class="responsibilityWrap">
              <div class="responsibility">任职要求：</div>
              <div class="responsibility" style="white-space: pre-wrap;">{{ infoDetail?.required }}</div>

            </div>
            <div class="borderss"></div>
            <div class="titles" style="margin-top: 20px;font-weight: 600">公司信息</div>
            <div class="companyInfo">
              <img style="border-radius: 6.67px" :src="infoDetail.company?.logo?.url">
              <div class="right" style="cursor: pointer" @click="goDetails">
                <div class="name">{{ infoDetail.company?.name }}</div>
                <div style="display: flex">
                  <div class="desc" v-for="item in infoDetail.company.tags" :key="item">
                    {{ item }}<span>·</span></div>
                </div>
              </div>
            </div>
          </div>

          <div class="borderss"></div>
          <div class="titles" style="margin-top: 20px">工作地址</div>
          <div class="adresss">
            <div class="item">
              <img src="https://static.dasdt.cn/images/adress2.png">
              <span>{{ infoDetail?.address }}</span>
            </div>
            <div class="item">
              <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
              <span>联系电话：{{ infoDetail.company?.phone }}</span>
            </div>
          </div>
          <div style="height: 300px;width: 100%;margin-top: 16px" @click="open">
            <MapContainer :info="infoDetail"/>
          </div>
        </div>
        <div class="course">
          <div class="courseWrap">
            <div class="title">课程推荐</div>
            <div class="curriculumList">
              <div class="item" v-for="(item,index) in curriculumList" :key="index" style="border: 1px solid #E6E6E6;height: 295px">
                <curriculumInfo :info="item"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rights">
        <div class="Evaluation">
          <img src="https://static.dasdt.cn/images/Evaluation.png">
        </div>
        <div class="Recruiting" v-if="false">
          <div class="head">
            <div class="title">在招职位{{ totalCount }}</div>
            <div class="more" @click="gengduo">
              <span>更多</span>
              <img src="https://static.dasdt.cn/images/right2.png">
            </div>
          </div>
          <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
              <div class="nameOrPrices" @click="goJobDetails(item.id)">
                <div class="name">{{ item.job }}</div>
                <div class="price">{{ item?.salary?.lowSalary }} - {{ item?.salary?.highSalary }}
                  {{ item?.salary?.duration }}<span v-if="item?.salary?.duration">薪</span>
                </div>
              </div>
              <div class="contentWrap" @click="goJobDetails(item.id)">
                <div class="infoList" style="margin-top: 0">
                  <div class="itemss">
                    <img src="https://static.dasdt.cn/images/education.png">
                    <span style="color: #999999;font-size: 12px">{{ item.education }}</span>
                  </div>
                  <div class="itemss">
                    <img src="https://static.dasdt.cn/images/experience.png">
                    <span style="color: #999999;font-size: 12px">{{ item.jobExp }}</span>
                  </div>
                </div>
                <div class="adress">{{ item.areaName }}</div>
              </div>

            </div>
          </div>
        </div>
        <div class="Evaluation">
          <img src="https://static.dasdt.cn/images/advertisement2.png">
        </div>
        <div class="Recruiting">
          <div class="head">
            <div class="title">相似岗位</div>
            <div class="more" @click="goResemble">
              <span>更多</span>
              <img src="https://static.dasdt.cn/images/right2.png">
            </div>
          </div>
          <div class="list" v-if="resembleList">
            <div class="item" v-for="(item, index) in resembleList" :key="index">
              <div class="nameOrPrices" @click="goJobDetails(item.id)">
                <div class="name shengluehao2">{{ item.job }}</div>
                <div class="price">{{ item?.salaryNote }}
                </div>
              </div>
              <div class="contentWrap" v-if="item.id!=infoDetail.id" @click="goJobDetails(item.id)">
                <div class="gongsi">
                  <img style="border-radius: 6.67px" :src="item.logo.url">
                  <span>{{ item.companyName }}</span>
                </div>
                <div class="adress">{{ item.areaName }}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    <floatRight/>
    <el-dialog
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose">
      <div class="promptTesting">
        <img src="https://static.dasdt.cn/images/empty2.png">
        <div class="font_1">您有待完成的岗位测评</div>
        <div class="font_2">是否继续投递？</div>
        <div class="btns">
          <div class="correct" @click="correct">是</div>
          <div class="deny" @click="dialogVisible = false">否</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisible2"
        width="400px"
        title="投递简历"
        :before-close="handleClose2">
      <div class="delivers">
        <div class="title">
          将向对方投递在线简历，请选择是否投递其他附件项？
        </div>
        <div class="duoxuan">
          <el-checkbox-group v-model="checkList" class="groupList">
            <el-checkbox class="item" label="1">简历附件1.pdf</el-checkbox>
            <el-checkbox class="item" label="2">简历附件2.pdf</el-checkbox>
            <el-checkbox class="item" label="3">测评结果1</el-checkbox>
            <el-checkbox class="item" label="4">测评结果2</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="btns">
          <div class="correct">取消</div>
          <div class="deny" @click="sending">发送</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisible3"
        width="400px"
        title="投递简历"
        :before-close="handleClose3">
      <div class="ImproveResume">
        <img src="https://static.dasdt.cn/images/ImproveResume.png">
        <div class="name">完善简历</div>
        <div class="desc">您的在线简历完整度不足80%，请先去 完善简历信息。</div>
        <div class="wanShan">去完善简历 精准推荐</div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisible4"
        center
        width="1200px"
        :before-close="handleClose4">
      <div class="mapDialog" style="padding-bottom: 20px" slot="footer">
        <div style="color: #141414;font-size: 18px;display:flex;font-weight: 500;margin-bottom: 16px">公司地址</div>
        <div class="adresss">
          <div class="item">
            <img src="https://static.dasdt.cn/images/adress2.png">
            <span>{{infoDetail?.address}}</span>
          </div>
          <div class="item">
            <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
            <span>联系电话：{{ infoDetail.company?.phone }}</span>
          </div>
        </div>
        <div style="height: 480px;width: 100%;margin-top: 16px">
          <MapContainer id="container2" :info="{...infoDetail,abc: 'abc'}"/>
        </div>
      </div>

    </el-dialog>
    <logins v-model="isShow2"/>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import MapContainer from "@/components/MapContainer/index.vue";
import curriculumInfo from "@/views/home/curriculumInfo.vue";
import Footer from "@/components/footer/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import {cancelByJid, jobMember, JobsDetail, memberCollection, pageJobs} from "@/api/position";
import {records} from "@/api/course";
import logins from "@/components/login/index.vue";

export default {
  components: {logins, floatRight, Footer, curriculumInfo, MapContainer, Header},
  data() {
    return {
      isShow: true,
      checkList: [1],
      dialogVisible2: false,
      dialogVisible: false,
      dialogVisible3: false,
      dialogVisible4: false,
      list: [],
      curriculumList: [
        {},
        {},
        {},
      ],
      infoDetail: {},
      totalCount: '',
      resembleList: [],
      moreShow: false
    }
  },
  mounted() {
    console.log(this.$route.query.id)
    scrollTo(0, 0)
    this.JobsDetail()
    this.getPageJobs()
    this.records()
  },
  computed: {
    isShow2() {
      return this.$store.getters.showLogin
    },
  },
  methods: {
    handleMouseEnter() {
      this.moreShow = true
    },
    handleMouseLeave() {
      this.moreShow = false
    },
    // 相似职位列表
    async getResembleJobs(categoryThree) {
      let params = {}
      params.categoryThree = categoryThree
      if (this.$store.getters.area.id) {
        params.areaSearch = this.$store.getters.area.id
      }
      params.auditState = 'SUCCESS'
      params.state = 'PUBLISHED'
      const res = await pageJobs(params, {number: 1, limit: 6})
      this.resembleList = res.data.filter(item => item.id!==this.infoDetail.id)
    },
    open() {
      this.dialogVisible4 = true
    },
    handleClose4() {
      this.dialogVisible4 = false
    },
    goJobDetails(id) {
      window.location.href = window.location.pathname + '?id=' + id
      // this.$router.replace(`/positionDetails?id=${id}`)
    },
    goResemble() {// 更多相似
      let id = this.infoDetail.categoryThree
      let level = 3
      const info = JSON.stringify({id, level})
      this.$router.push(`/position?info=${info}`)
    },
    gengduo() {
      this.$router.push('/position')
    },
    // 职位列表
    async getPageJobs() {
      const res = await pageJobs({}, {number: 1, limit: 5})
      this.list = res.data
      this.totalCount = res.totalCount
    },
    // 课程列表
    async records() {
      const res = await records({firstClassifyId: 1}, {number: 1, limit: 6})
      this.curriculumList = res.data
    },
    // 获取招聘岗位详情
    async JobsDetail() {
      const {data} = await JobsDetail(this.$route.query.id)
      console.log(data)
      this.isShow = !data[0].isCollection
      if (data[0].jobTagList) {
        data[0].tagList = []
        data[0].tagList = [...data[0].tagList, ...data[0].jobTagList]
      }
      if (data[0].customJobTag) {
        data[0].tagList = [...data[0].tagList, ...data[0].customJobTag]
      }
      if(data[0].company.customFirmTag) {
        data[0].company.tags = [...data[0].company.tags, ...data[0].company.customFirmTag]

      }
      if(data[0].customBonusTag) {
        data[0].bonusList = [...data[0].customBonusTag, ...data[0].bonusList]

      }
      this.infoDetail = data[0]
      this.getResembleJobs(this.infoDetail.categoryThree)
    },
    async eventCollection() {
      if (this.isShow) {
        const {data} = await memberCollection({type: 'POSITION', jid: this.$route.query.id})
        console.log(data)
        this.$message({
          message: '收藏成功',
          type: 'success'
        });
      } else {
        const {data} = await cancelByJid(this.$route.query.id)
        console.log(data)
        this.$message({
          message: '取消成功',
          type: 'success'
        });
      }
      this.isShow = !this.isShow
    },
    // 复制链接
    copyCurrentPageLink() {
      let url = window.location.href;
      let inputNode = document.createElement("input"); // 创建input
      inputNode.value = url; // 赋值给 input 值
      document.body.appendChild(inputNode); // 插入进去
      inputNode.select(); // 选择对象
      document.execCommand("Copy"); // 原生调用执行浏览器复制命令
      inputNode.className = "oInput";
      inputNode.style.display = "none"; // 隐藏
      this.$message({
        message: '复制成功',
        type: 'success'
      });

    },
    // 跳转企业详情
    goDetails() {
      this.$router.push(`/enterpriseDetails?id=${this.infoDetail.company.id}`)
    },
    async deliver() {
      console.log(111111111111, Object.keys(this.$store.getters.userInfo).length)
      if(Object.keys(this.$store.getters.userInfo).length>0){
        const info = this.$store.getters.userInfo
        if(info.customJobTag?.length === 0 || !info.birthDay || !info.email || info.working?.length === 0 || info.project?.length === 0 || info.cateIdsComment?.length === 0) {
          this.$message({
            message: '请先完善个人简历',
            type: 'warning'
          });
          this.$router.push('/PersonalCenter?deletion=1')
          return
        }
      }
      const res = await jobMember({
        advantages: '',
        jid: this.infoDetail.id,
        bid: this.infoDetail.bid,
      })
      if (res.code === 'A00000') {
        this.infoDetail.isDeliver = !this.infoDetail.isDeliver
        this.$message({
          message: '投递成功',
          type: 'success'
        })
      }else{
        this.$message({
          message: res.message,
          type: 'warning'
        })
      }
      console.log(res)
    },
    correct() {
      this.dialogVisible2 = true
      this.dialogVisible = false

    },
    sending() {
      this.dialogVisible3 = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    handleClose3() {
      this.dialogVisible3 = false
    },
  }
}
</script>
<style lang="scss">
.shareList {
  display: flex;
  flex-direction: column;

  .item {
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 6px;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.positionDetails {
  overflow: hidden;
  background: #F5F5F6;

  .adresss {
    display: flex;
    align-items: center;

    .item {
      width: 50%;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .delivers {
    margin-top: 10px;

    .btns {
      margin-top: 16px;
      padding-bottom: 18px;
      display: flex;
      align-items: center;
      gap: 10px;

      .correct {
        cursor: pointer;
        border: 1px solid #808080;
        width: 170px;
        height: 44px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .deny {
        cursor: pointer;
        background: #3A76FF;
        width: 170px;
        height: 44px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .duoxuan {
      .groupList {
        display: flex;
        flex-direction: column;

        .item {
          height: 46px;
          width: 100%;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #E5E6EB !important;
        }

        .item:not(:last-child) {
          border-bottom: 1px solid #E5E6EB !important;
        }
      }
    }

    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .ImproveResume {
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 148px;
      height: 102px;
    }

    .name {
      color: #333333;
      font-size: 22px;
      font-weight: 500;
      margin-top: 16px;
    }

    .desc {
      color: #333333;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-top: 12px;
    }

    .wanShan {
      cursor: pointer;
      margin-top: 24px;
      width: 100%;
      height: 44px;
      border-radius: 100px;
      background: #3A76FF;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .promptTesting {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 116px;
      height: 88px;
    }

    .font_1 {
      color: #333333;
      font-size: 22px;
      font-weight: 500;
    }

    .font_2 {
      margin-top: 10px;
      color: #333333;
      font-weight: 400;
      font-size: 16px;
    }

    .btns {
      margin-top: 48px;
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      gap: 10px;

      .correct {
        cursor: pointer;
        border: 1px solid #808080;
        width: 170px;
        height: 44px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .deny {
        cursor: pointer;
        background: #3A76FF;
        width: 170px;
        height: 44px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .infoList {
    display: flex;
    align-items: center;
    gap: 24px;

    .itemss {
      display: flex;
      align-items: center;
      gap: 6px;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .detailsWrap {
    position: relative;
    z-index: 9;
    margin: 24px auto;
    width: 1200px;
    display: flex;
    gap: 16px;

    .lefts {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .course {
        border-radius: 8px;
        background: #ffffff;
        width: 900px;

        .courseWrap {
          padding: 16px;

          .title {
            color: #333333;
            font-size: 18px;
            font-weight: 500;
          }

          .curriculumList {
            margin-top: 16px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            align-items: center;

            .item {
              width: 278px;
              //height: 294px;
              border-radius: 8px;
              border: 1px solid #FFFFFF;
              background: #FFFFFF;
              overflow: hidden;
            }
          }
        }
      }

      .details {
        border-radius: 8px;
        padding: 16px;
        width: 900px;
        background: #fff;

        .ViewMores {
          margin: 32px auto;
          width: 200px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #185BE0;
          color: #185BE0;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          cursor: pointer;
        }

        .companyInfo {
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            width: 44px;
            height: 44px;
          }

          .right {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .name {
              color: #333333;
              font-size: 16px;
              font-width: 500;
            }

            .desc {
              color: #808080;
              font-size: 14px;
              display: flex;

              span {
                display: none;
              }
            }

            .desc:not(:last-child) {
              span {
                display: block !important;
              }
            }
          }
        }

        .borderss {
          margin-top: 20px;
          border-bottom: 1px solid #E6E6E6;
        }

        .responsibilityWrap {
          margin-top: 20px;

          .responsibility {
            color: #333333;
            font-size: 14px;
            line-height: 26px;
            font-width: 400;
          }
        }

        .titles {
          margin-bottom: 16px;
          margin-top: 32px;
          color: #141414;
          font-size: 18px;
          font-width: 500;

        }
      }
    }

    .rights {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .Recruiting {
        background: #ffffff;
        border-radius: 8px;
        width: 286px;
        min-height: 390px;
        border: 1px solid #ffffff;

        .list {
          margin: 6px 16px 16px 16px;
          display: flex;
          flex-direction: column;
          gap: 26px;

          .item {
            .contentWrap {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 9px;

              .gongsi {
                display: flex;
                align-items: center;
                gap: 6px;

                img {
                  width: 20px;
                  height: 20px;
                }

                span {
                  color: #999999;
                  font-size: 12px;
                  font-weight: 400;
                }
              }

              .adress {
                color: #999999;
                font-size: 12px;
                font-weight: 400;
              }
            }

            .nameOrPrices {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .name {
                color: #141414;
                font-size: 16px;
                font-weight: 400;
              }

              .price {
                color: #FD5056;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }

        .head {
          margin: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            color: #333333;
            font-size: 18px;
            font-weight: 500;
          }

          .more {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;

            img {
              width: 3px;
              height: 6px;
            }

            span {
              color: #999999;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .Evaluation {
        cursor: pointer;
        width: 286px;
        height: 114px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .positionDetailsHeader {
    position: relative;
    z-index: 10;
    height: 122px;
    background: #fff;

    .wrap {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      position: relative;

      .middleWrap {

        height: 30px;
      }

      .middle {
        display: flex;
        align-items: center;
        gap: 6px;
        position: relative;

        .more {
          box-shadow: 0px 4px 14px 0 #0000001A;
          top: 45px;
          left: 0;
          //right: 0;
          position: absolute;
          border-radius: 8px;
          background: #fff;

          .welfares {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 14px;
            width: 420px;
            flex-wrap: wrap;

            .item {
              border-radius: 4px;
              background: #F6F6F6;
              padding: 8px 10px;
              color: #555555;
              font-size: 14px;
            }
          }
        }

        img {
          cursor: pointer;
          width: 30px;
          height: 30px;
        }

        .welfare {
          display: flex;
          align-items: center;
          gap: 6px;

          .item {
            border-radius: 4px;
            background: #F6F6F6;
            padding: 8px 10px;
            color: #555555;
            font-size: 14px;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .timer {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          justify-content: flex-end;
        }

        .btns {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 14px;

          .deliver2 {
            background: #475774;
            border-radius: 4px;
            width: 140px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ADADAD;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
          }

          .deliver {
            background: #3A76FF;
            border-radius: 4px;
            width: 140px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
          }

          .share2 {
            background: #364767;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            border-radius: 4px;
            padding: 0 16px;
            //width: 80px;
            height: 38px;
            cursor: pointer;

            img {
              width: 14px;
              height: 14px;
            }

            span {
              color: #ADADAD;
              font-size: 14px;
              font-width: 400;
            }
          }

          .share {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            border: 1px solid #ADADAD;
            border-radius: 4px;
            width: 80px;
            height: 38px;
            cursor: pointer;

            img {
              width: 14px;
              height: 14px;
            }

            span {
              color: #555555;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .nameOrPrice {
          display: flex;
          align-items: center;
          gap: 10px;

          .name {
            color: #141414;
            font-size: 30px;
            font-weight: 600;
          }

          .price {
            color: #FD5056;
            font-size: 30px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
